import React, { useEffect, useState } from 'react'
import { Button, Select, Table, TableColumnsType, TableProps, Tooltip } from 'antd'
import { RouterInput, RouterOutput, trpc } from '../../utils/trpc'
import { Title } from '../../components/Title'
import { BarcodeOutlined, DownloadOutlined, WarningTwoTone } from '@ant-design/icons'
import { DeliveryOptionModel, PickingState, Route } from '@wolf/entities'
import { BoxLabelTemplate } from './components/BoxLabelTemplate'
import { useNavigate } from 'react-router-dom'
import { useUrlDeliveryParams } from './hooks/useUrlDeliveryParams'
import { usePageOutletContext } from '../../page-outlet-context'
import { useUrlPickingParams } from './hooks/useUrlPickingParms'
import { useUrlIdsParams } from './hooks/useUrlIdsParams'
import { useUrlRouteParams } from './hooks/useUrlRouteParams'
import { STATE_COLOURS } from '../../state-colours'
import { dayNames } from '../../utils/day-names'
import { Helmet } from 'react-helmet-async'
import { RoutesFilter } from './components/filters/RoutesFilter'
import { DeliveryFilter } from './components/filters/DeliveryFilter'
import { BaseFilters } from './components/filters/BaseFilters'
import { useUrlFilterTypeParams } from './hooks/useUrlFilterTypeParams'
import { getPickingState } from '../picking/picking.utils'
import { format, setDay } from '@wolf/dates'
import { useUrlOtherParams } from './hooks/useUrlOtherParams'
import { CommonTableProps } from '../../components/table-props'

type DataType = {
  serialId: number
  deliveryGroup: { serialId: number; currentRoute?: Route | null; metadata: { orders: Array<number> } }
  boxType: { name: string }
}
type PaginatedBox = RouterOutput['boxes']['getPaginatedBoxes']['data'][number]

type Sort = RouterInput['boxes']['getPaginatedBoxes']['sortParams']

const classStates: Record<PickingState, string> = {
  'EMPTY': STATE_COLOURS.EMPTY,
  'PARTIAL': STATE_COLOURS.PARTIAL,
  'COMPLETED': STATE_COLOURS.COMPLETED,
  'UNMOUNT': STATE_COLOURS.ERROR,
} as const

export const formatDeliveryOption = (deliveryOption: DeliveryOptionModel, deliveryDateStart?: Date): string =>
  `${format(deliveryDateStart ? deliveryDateStart : setDay(new Date(), deliveryOption.day), 'EEEE')} ${deliveryOption.start}-${deliveryOption.end}`.toUpperCase()

export const formatDeliveryDay = (date: PaginatedBox['deliveryGroup']['deliveryDate']): string =>
  `${format(date.start, 'dd/MM/yyyy')}`

export const Boxes: React.FC = () => {

  const [pageSize, setPageSize] = useState<number>(10)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [sortParams, setSortParams] = useState<Sort>({
    'deliveryGroupId': 'descend'
  })

  const [boxesToPrint, setBoxesToPrint] = useState<{ boxId: number | 'all' } | null>(null)

  const { salesGroupFilter, sourceFilter } = usePageOutletContext()
  const navigate = useNavigate()

  const { deliveryParams } = useUrlDeliveryParams()
  const { pickingParams } = useUrlPickingParams()
  const { otherParams } = useUrlOtherParams()
  const { idsParams } = useUrlIdsParams()
  const { routeParams } = useUrlRouteParams()
  const { filterTypeParams, changeFilterTypeParams } = useUrlFilterTypeParams()

  const getSelectedFilter = () => {

    if (filterTypeParams === 'delivery') {
      return {
        ...deliveryParams,
        type: filterTypeParams
      }
    }

    return {
      ...routeParams,
      type: filterTypeParams
    }
  }

  const baseQuery = {
    sourceId: sourceFilter.serialId,
    salesGroupId: salesGroupFilter.salesGroupId,
    shouldChangeLabel: otherParams.shouldChangeLabel,
    isManagedDelivery: otherParams.isManagedDelivery ?? undefined,
    searchBoxId: idsParams.boxId,
    searchDeliveryId: idsParams.deliveryId,
    searchOrderId: idsParams.orderId,
    sortParams,
    pickingStates: pickingParams.pickingStates,
    selectedFilter: getSelectedFilter()
  }

  const paginatedBoxesQuery = trpc.boxes.getPaginatedBoxes.useQuery({
    ...baseQuery,
    pagination: {
      currentPage,
      pageSize,
    }
  }, {
    enabled: baseQuery.salesGroupId > 0
  })

  const updateBoxesShouldChangeLabel = trpc.boxes.updateBoxShouldChangeLabel.useMutation()

  const boxLabelInputQuery = trpc.boxes.getPaginatedBoxes.useQuery({
    ...baseQuery,
    pagination: 'all',
    ...{ searchBoxId: boxesToPrint?.boxId === 'all' ? baseQuery.searchBoxId : boxesToPrint?.boxId }
  }, {
    enabled: boxesToPrint !== null,
    cacheTime: 0,
    onSuccess: (result) => {
      updateBoxesShouldChangeLabel.mutate({
        boxesId: result.data.map(({ serialId }) => serialId),
        shouldChangeLabel: false
      })
    }
  })

  const boxLabelInput = boxLabelInputQuery.data?.data.map((box) => ({
    address: box.deliveryGroup.address,
    boxId: box.serialId,
    boxTypeName: box.boxType.name,
    deliveryGroupId: box.deliveryGroupId,
    deliveryGroupIndex: box.deliveryGroupIndex,
    deliveryOption: formatDeliveryOption(box.deliveryGroup.deliveryOption, box.deliveryGroup.deliveryDate.start),
    deliveryGroupTotalBoxes: box.deliveryGroup.boxes.filter((box) => box.pickingState !== 'UNMOUNT').length,
    route: box.deliveryGroup.currentRoute ?? { day: 0, route: 'SIN RUTA' }
  }))

  const columns: TableColumnsType<DataType> = [
    {
      width: 50,
      key: 'shouldChangeLabel', dataIndex: 'shouldChangeLabel',
      render: (shouldChangeLabel: PaginatedBox['shouldChangeLabel']) => {
        return (
          shouldChangeLabel &&
          <Tooltip title="El envío ha cambiado, comprobar ruta y etiqueta">
            <WarningTwoTone className='text-2xl' twoToneColor='#eb2f96' />
          </Tooltip>
        )
      }
    },
    {
      title: 'ID Caja', key: 'serialId', dataIndex: 'serialId', sorter: { multiple: 1 }
    },
    {
      title: 'Tamaño', key: 'boxType', dataIndex: ['boxType', 'name'], sorter: { multiple: 1 },
    },
    {
      title: 'ID Envío (Optimo)', key: 'deliveryGroupId', dataIndex: ['deliveryGroup', 'serialId'], sorter: { multiple: 1 }, defaultSortOrder: 'descend'
    },
    {
      title: 'Zona', key: 'deliveryZoneName', dataIndex: ['deliveryGroup', 'deliveryZone', 'name']
    },
    {
      title: 'Entrega', key: 'deliveryOption', dataIndex: 'deliveryGroup',
      render: (deliveryGroup: PaginatedBox['deliveryGroup']) => `${formatDeliveryDay(deliveryGroup.deliveryDate)} (${formatDeliveryOption(deliveryGroup.deliveryOption, deliveryGroup.deliveryDate.start)})`
    },
    {
      title: 'Ruta', key: 'route', dataIndex: ['deliveryGroup', 'currentRoute'],
      render: (route) => {
        if (route && route.route && route.day > -1) {
          return `${route.route} - ${dayNames[route.day]}`
        }
      }
    },
    {
      title: 'Parada', key: 'stop', dataIndex: ['deliveryGroup', 'currentRoute', 'stop'],
    },
    {
      title: 'Pedidos', key: 'orders', dataIndex: ['deliveryGroup', 'metadata', 'orders'],
      render: (orders: Array<number>) => {
        const ordersToText = orders.map((order, index) => index === orders.length - 1 ? `${order}` : `${order}, `)
        return <Tooltip title={ordersToText} placement='left'>
          <p className='m-0 max-w-12 overflow-hidden whitespace-nowrap text-ellipsis'>
            {ordersToText}
          </p>
        </Tooltip>
      }
    },
    {
      title: 'Estado', key: 'pickingState', dataIndex: 'picking',
      render: (picking: PaginatedBox['picking']) => {
        return (<span className={`${classStates[getPickingState(picking)]} py-0.5 px-4 border border-solid rounded`} />)
      }
    },
    {
      dataIndex: [],
      key: 'actions',
      className: 'text-center',
      render: (box: PaginatedBox) => {
        return (
          <>
            <Button type="primary" icon={<DownloadOutlined />} loading={boxLabelInputQuery.isInitialLoading} onClick={() => {
              setBoxesToPrint({
                boxId: box.serialId
              })
            }} />
            <Button
              type='primary'
              icon={<BarcodeOutlined />}
              className={'ml-2'}
              onClick={() => navigate(`/picking/${box.serialId}`)}
            >
            </Button>
          </>
        )
      }
    }
  ]

  const onPaginationChange = (current: number, pageSize: number) => {
    setCurrentPage(current)
    setPageSize(pageSize)
  }

  const onTableChange: TableProps<DataType>['onChange'] = (_, __, sorter) => {
    const changedSort = Array.isArray(sorter) ? sorter : [sorter]

    const toSort = changedSort.reduce<Sort>((allSorts, sort) => {
      if (sort.columnKey) {
        return {
          [sort.columnKey]: sort.order
        }
      }

      return allSorts
    }, {})

    return setSortParams(toSort)
  }

  useEffect(() => {
    setCurrentPage(1)
    // We need stringify because the parameters are objects, and even if none of them change, the reference does change
  }, [JSON.stringify({ idsParams, routeParams, deliveryParams, pickingParams })])

  return (
    <>
      <Helmet
        title='Cajas - Wolf'
      />

      <Title>Cajas</Title>

      <BaseFilters />
      <div className='flex flex-wrap mr-4'>
        <div className='flex mb-4 ml-1 mr-3'>
          <p className='mr-2'>Filtrar por:</p>
          <Select
            className='self-center w-24 h-8'
            value={filterTypeParams}
            options={[
              { label: 'Ruta', value: 'route' },
              { label: 'Entrega', value: 'delivery' }
            ]}
            onChange={(value) => changeFilterTypeParams(value)}
          />
        </div>
        {filterTypeParams === 'route' && <RoutesFilter />}
        {filterTypeParams === 'delivery' && <DeliveryFilter />}
        {paginatedBoxesQuery.data && (
          <Button className='self-center mb-4 ml-auto' type="primary" loading={boxLabelInputQuery.isInitialLoading} icon={<DownloadOutlined />} onClick={() => {
            setBoxesToPrint({
              boxId: 'all'
            })
          }}>Descargar Etiquetas</Button>
        )}
      </div>

      <Table
        {...CommonTableProps}
        className='border-solid border border-slate-200 rounded-lg mb-4 overflow-auto'
        columns={columns}
        scroll={{ x: 1500 }}
        dataSource={paginatedBoxesQuery.data?.data}
        loading={paginatedBoxesQuery.isLoading}
        onChange={onTableChange}
        rowKey={(box) => box.serialId}
        pagination={
          {
            className: 'pr-4',
            total: paginatedBoxesQuery.data?.total,
            pageSize,
            current: currentPage,
            showSizeChanger: true,
            onChange: onPaginationChange
          }
        }
      />

      <div className='border-solid border border-slate-200 rounded-lg flex justify-center'>
        <p className='pr-8'>Estados:</p>
        <p className='pr-8'> <span className={`${STATE_COLOURS.EMPTY} py-0.5 px-4 mr-1 border border-solid rounded`} /> Vacía</p>
        <p className='pr-8'><span className={`${STATE_COLOURS.PARTIAL} py-0.5 px-4 mr-1 border border-solid rounded`} /> Parcialmente montada</p>
        <p className='pr-8'><span className={`${STATE_COLOURS.COMPLETED} py-0.5 px-4 mr-1 border border-solid rounded`} /> Completamente montada</p>
        <p className='pr-8'><span className={`${STATE_COLOURS.ERROR} py-0.5 px-4 mr-1 border border-solid rounded`} /> Desmontar</p>
      </div>

      {
        boxesToPrint && boxLabelInput && boxLabelInput.length > 0 && (
          <BoxLabelTemplate
            boxLabelInput={boxLabelInput}
            onClose={() => {
              paginatedBoxesQuery.refetch()
              setBoxesToPrint(null)
            }}
          />
        )
      }
    </>
  )
}
